import React from 'react';
import {
  CodeSnippet,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
  PropList,
  PropListItem,
  PageNavigation,
  PageNavigationLink,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Button</PageNavigationLink>
        <PageNavigationLink>Minimal</PageNavigationLink>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Disabled</PageNavigationLink>
      </PageNavigation>
      <Section title="Button">
        <Paragraph>Used to create standard buttons.</Paragraph>

        <CodeSnippet
          code="@include button($variant);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.buttons.scss#L541"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$variant" types={['list-map value']}>
              <Text>
                Default value: <code>secondary</code>
              </Text>
              <Text>Pass one of the following values:</Text>
              <List type="unordered">
                <li>
                  <code>primary</code>
                </li>
                <li>
                  <code>secondary</code>
                </li>
                <li>
                  <code>subtle</code>
                </li>
                <li>
                  <code>destroy</code>
                </li>
                <li>
                  <code>confirm</code>
                </li>
                <li>
                  <code>cancel</code>
                </li>
                <li>
                  <code>disabled</code>
                </li>
              </List>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Minimal">
        <CodeSnippet
          code="@include button-minimal($variant);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.buttons.scss#L367"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$variant" types={['list-map value']}>
              <Text>
                Default value: <code>secondary</code>
              </Text>
              <Text>Pass one of the following values:</Text>
              <List type="unordered">
                <li>
                  <code>primary</code>
                </li>
                <li>
                  <code>secondary</code>
                </li>
                <li>
                  <code>subtle</code>
                </li>
                <li>
                  <code>destroy</code>
                </li>
                <li>
                  <code>success</code>
                </li>
                <li>
                  <code>cancel</code>
                </li>
                <li>
                  <code>disabled</code>
                </li>
              </List>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Size">
        <CodeSnippet
          code="@include button-size($size);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.buttons.scss#L320"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$size" types={['list-map value']}>
              <Text>
                Default value: <code>medium</code>
              </Text>
              <Text>Pass one of the following values:</Text>
              <List type="unordered">
                <li>
                  <code>xsmall</code>
                </li>
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code>
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Disabled">
        <CodeSnippet
          code="@include button-disabled($style);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.buttons.scss#L258"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$style" types={['string']}>
              <Text>
                Default value: <code>regular</code>
              </Text>
              <Text>Pass one of two values:</Text>
              <List type="unordered">
                <li>
                  <code>regular</code>
                </li>
                <li>
                  <code>minimal</code>
                </li>
              </List>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
